import React from 'react';

const Checkbox = props => {
	const { id = 'checkbox', name = 'checkbox', title, children, value = true } = props;
	const onBlur = (evt) => {
        props.onBlur && props.onBlur()
        
    }

    const onFocus = (evt) => {
        props.onFocus && props.onFocus(evt)
    }

    const onInput = (evt) => {
        props.onInput && props.onInput(evt)
    }
	return (
		<div className="checkbox">
			<input
				className="checkbox__field"
				type="checkbox"
				name={name}
				id={id}
				onInput={onInput}
			/>
			<label className="checkbox__label" htmlFor={id}>
				<p className="checkbox__text">
					<span className="checkbox__title">{title}</span>
					{children}
				</p>
			</label>
		</div>
	);
};

export default Checkbox;
