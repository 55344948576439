import React, { useState, useEffect, useRef } from "react";
import SimpleBar from "simplebar-react";

function Dropdown(props) {
    let {name, required, title, type, options, activeOptionIndex, readonly, visibleOptions, onChangeInput, value} = props;
    onChangeInput = onChangeInput ? onChangeInput : _ => {}


    const [activeOption, setActiveOption] = useState(
        activeOptionIndex ?
            activeOptionIndex
        :
            {
                key: "",
                value: ""
            }
    );

    const [isOpen, setIsOpen] = useState(false);
    const [wrapperHeight, setWrapperHeight] = useState();
    const [optionHeight, setOptionHeight] = useState();
    const [wrapperCurrentHeight, setWrapperCurrentHeight] = useState();
    const rootEl = useRef(null);
    const wrapperEl = useRef(null);
    const optionEl = useRef(null);

    useEffect(() => {
        const onClick = e => rootEl.current.contains(e.target) ||  toggleState();
        document.addEventListener('click', onClick);
        return () => document.removeEventListener('click', onClick);
      });

    useEffect(() => {
        setWrapperHeight(wrapperEl.current.getBoundingClientRect().height);
        setOptionHeight(optionEl.current ? optionEl.current.getBoundingClientRect().height : 0);

        setWrapperCurrentHeight(_ => setHeightWrapper(isOpen));
    }, [options]);

      
    const toggleState =(open = false) => {
        setIsOpen(open)
        setWrapperCurrentHeight(_ => setHeightWrapper(open));
    }

    const setOption = (optionIndex, e) => {
        e.preventDefault();
        
        toggleState(false);
        setActiveOption(options[optionIndex]);
        onChangeInput(options[optionIndex]);
    }

    const setHeightWrapper = (open) => {
        if (open) {
            if (options.length < 4) {
                return (wrapperHeight + (optionHeight * options.length)) + "px";
            } else {
                return (wrapperHeight + (optionHeight * 4)) + "px";
            }
        } else {
            return wrapperHeight + "px";
        }
    };

    const onFocus = (e) => {

    }

    const updateInputValue = (e) => {
        const value = e.target.value;

        setActiveOption({
            key: "write",
            value: value
        })

        onChangeInput(activeOption);
    }

    let style = {
        height: wrapperCurrentHeight
    };

    return (
        <>
            <div className={`dropdown ${isOpen ? " is-open" : ""}`} data-input-wrapper="" data-scroll="" data-scroll-fadeup="" ref={rootEl}>
                <div className="dropdown__wrapper" ref={wrapperEl}>
                    <input 
                        className="dropdown__input" 
                        type={type ? type : "text"}
                        placeholder={title}
                        name={name ? name : 'default'}
                        required={required ? required : true}
                        autoComplete="off"
                        value={activeOption.value}
                        readOnly={readonly === false ? false : true}
                        onChange={e => {
                            updateInputValue(e)
                            onChangeInput(activeOption)
                        }}
                        onClick={_ => {toggleState(!isOpen)}}
                        onFocus={readonly === false ? e => {onFocus(e)} : _ => {}}
                    />
                    <input 
                        type={type ? type : "text"}
                        name={name ? `${name}__hidden` : 'default__hidden'}
                        value={activeOption.key}
                        hidden
                        onChange={_ => {}}
                    />
                    <div className="dropdown__arrow">
                        <svg className="icon">
                            <use xlinkHref="#arrow_dropdown"></use>
                        </svg>
                    </div>
                    <SimpleBar 
                        className="dropdown__options"
                    >
                        {options.map((option, index) => {
                            return (
                                <button ref={optionEl}
                                className={`dropdown__option ${(activeOption && activeOption === option) ? "is-active" : ""}`}
                                key={index} 
                                onClick={e => {setOption(index, e)}}
                                // dangerouslySetInnerHTML={{__html: option.value}}
                                >
                                    {option.value}
                                </button>
                            )
                        })}
                    </SimpleBar>
                </div>
            </div>
        </>
    )
}

export default Dropdown;