import * as Actions from "../actions/formAction";

const initialState = {
    state: "Init"
}


export function formUpdate(state = initialState, action) {
  switch (action.type) {
    case 'ADD_VALUES':
      return state = action.value
    default:
      return state
  }
}