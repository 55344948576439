import React from "react";

function InfoList(props) {
    const {title, data} = props

    return (
        <>
            <div className="main__info main_info" data-scroll="" data-scroll-fadeup="">
                <h2 className="section_block__title" data-scroll="" data-scroll-fadeup="">{title}</h2>
                <div className="main_info__list" data-scroll="" data-scroll-fadeup="">
                    {data.map( (item, index) => {
                        return (
                            <div className="main_info__item" key={index}>
                                <div className="main_info__item_number" data-scroll="" data-scroll-fadeup="">
                                    <span>{index + 1}</span>
                                </div>
                                <p className="section_block__text" data-scroll="" data-scroll-fadeup="">{item.text}</p>
                            </div>
                        )
                    })}
                </div>
            </div>
        </>
    )
}

export default InfoList = React.memo(InfoList);