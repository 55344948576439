import React, { useState, useEffect } from "react";
import {
    useParams,
    Link,
    Navigate,
    useNavigate
  } from "react-router-dom";
import PageTop from "../components/pageTop/PageTop";
import { filterAgencies } from "../libs/frontFilters";
import {store} from "../store/store";

function Agency() {
    const { id } = useParams();
    const navigate = useNavigate();
    // const [isLoading, setIsLoading] = useState(true);
    // const [data, setData] = useState();


    // useEffect( _ => {
    //     const fetchData = async () => {
    //         // if (store.getState().agencies.length === 0) {

    //         setIsLoading(true);

    //         // const query = store.getState().form;

    //         const res = await filterAgencies(id, store);

    //         // console.log(res);

    //         setData(res);

    //         setIsLoading(false);
    //     }

    //     fetchData();
    // });
    
    const data = filterAgencies(id, store);
    const formData = store.getState().form;

    store.dispatch({
        type: 'ADD_VALUES',
        value: {
                    ...formData,
                    agency: data
                }
    })

    if (!data) {
        navigate(`/form`);

        return
    }

    // if (isLoading) {
    //     <>
    //         <section className="section agencies">  
    //             <div className="agencies__container container">
    //                 <div className="agencies__content">
    //                     <h1 className="title download_text">Загрузка...</h1>
    //                 </div>
    //             </div>
    //         </section>
    //     </>
    // } else {
        return (
            <>
                <section className="section card"> 
                    <div className="card__container container">
                        <PageTop
                            title="Охранные агентства"
                        />
                        <div className="card__content" data-scroll="" data-scroll-fadeup="">
                            <div className="agencies_card" data-scroll="" data-scroll-fadeup="">
                                <div className="agencies_card__top" data-scroll="" data-scroll-fadeup="">
                                    <div className="agencies_card__top_icon"> 
                                        <img className="image" src={data.image.path} alt=""/>
                                    </div>
                                    <div className="agencies_card__top_info">
                                        <h3 className="section_block__title">{data.name}</h3>
                                        <p className="section_block__text">{data.adress}</p>
                                    </div>
                                </div>
                                <div className="agencies_card__line" data-scroll="" data-scroll-fadeup="">
                                    <div className="agencies_card__block">
                                        <div className="agencies_card__block_icon"> 
                                            <svg className="icon">
                                                <use xlinkHref="#star"></use>
                                            </svg>
                                        </div>
                                        <p className="agencies_card__block_text">4.9</p>
                                    </div>
                                <div className="agencies_card__block">
                                    <div className="agencies_card__block_icon"> 
                                        <svg className="icon">
                                            <use xlinkHref="#clock"></use>
                                        </svg>
                                    </div>
                                    <p className="agencies_card__block_text">{Math.round(data.mapData.routes[0].duration / 60)} минут</p>
                                </div>
                                    <div className="agencies_card__block">
                                        <div className="agencies_card__block_icon"> 
                                            <svg className="icon">
                                                <use xlinkHref="#ruble"></use>
                                            </svg>
                                        </div>
                                        <p className="agencies_card__block_text">от {new Intl.NumberFormat('ru-RU').format(data.price)} ₽ в месяц</p>
                                    </div>
                                </div>
                                <div className="section_block__text section_block__text--card" data-scroll="" data-scroll-fadeup="" dangerouslySetInnerHTML={{__html: data.description}}></div>
                                <div className="card__answers">
                                <div className="card__answers_line" data-scroll="" data-scroll-fadeup="">
                                    <p className="card__answers_name">Страхование ответственности </p>
                                    <span className={`card__answers_span ${data.insurance === 1 ? "" : " card__answers_span--grey"}`}>
                                        {data.insurance === 1 ? "Да" : "Нет"}
                                    </span>
                                </div>
                                <div className="card__answers_line" data-scroll="" data-scroll-fadeup="">
                                    <p className="card__answers_name">Мобильное приложение</p>
                                    <span className={`card__answers_span ${data.mobile_app === 1 ? "" : " card__answers_span--grey"}`}>
                                        {data.mobile_app === 1 ? "Да" : "Нет"}
                                    </span>
                                </div>
                                <div className="card__answers_line" data-scroll="" data-scroll-fadeup="">
                                    <p className="card__answers_name">Онлайн оплата услуг</p>
                                    <span className={`card__answers_span ${data.online_payments === 1 ? "" : " card__answers_span--grey"}`}>
                                        {data.online_payments === 1 ? "Да" : "Нет"}
                                    </span>
                                </div>
                                <div className="card__answers_line" data-scroll="" data-scroll-fadeup="">
                                    <p className="card__answers_name">Аренда оборудования</p>
                                    <span className={`card__answers_span ${data.rent === 1 ? "" : " card__answers_span--grey"}`}>
                                        {data.rent === 1 ? "Да" : "Нет"}
                                    </span>
                                </div>
                                <div className="card__answers_line" data-scroll="" data-scroll-fadeup="">
                                    <p className="card__answers_name">Ложный выезд ГБР</p>
                                    <span className={`card__answers_span ${data.false_exit === null ? "" : " card__answers_span--grey"}`}>
                                        {data.false_exit === null ? "Бесплатно" : `${new Intl.NumberFormat('ru-RU').format(data.false_exit)} ₽`}
                                    </span>
                                </div>
                                <div className="card__answers_line" data-scroll="" data-scroll-fadeup="">
                                    <p className="card__answers_name">Беспроводные системы</p>
                                    <span className={`card__answers_span ${data.wireless_systems === 1 ? "" : " card__answers_span--grey"}`}>
                                        {data.wireless_systems === 1 ? "Да" : "Нет"}
                                    </span>
                                </div>
                                </div>
                                <div className="agencies_card__bottom" data-scroll="" data-scroll-fadeup=""><Link className="agencies_card__bottom_link" to={`/reviews/${data.id}`}><span className="text">{data.reviewsTotal.total} отзывов</span>
                                    <svg className="icon">
                                    <use xlinkHref="#arrow_short"></use>
                                    </svg></Link>
                                <div className="agencies_card__bottom_right">
                                    <div className="agencies_card__like">
                                    <div className="agencies_card__like_icon"> 
                                        <svg className="icon_up">
                                        <use xlinkHref="#thumbs-up"></use>
                                        </svg>
                                    </div><span className="section_block__text">{data.reviewsTotal.plus}</span>
                                    </div>
                                    <div className="agencies_card__like">
                                    <div className="agencies_card__like_icon"> 
                                        <svg className="icon_down">
                                        <use xlinkHref="#thumbs-down"></use>
                                        </svg>
                                    </div><span className="section_block__text">{data.reviewsTotal.minus}</span>
                                    </div>
                                </div>
                                </div>
                                <Link className="card__btn btn btn--filled" to="/application_form" data-scroll="" data-scroll-fadeup="">
                                    <span className="btn__text">Оставить заявку</span>
                                </Link>
                            </div>
                        </div>
                    </div>
                </section>
            </>
        )
    // }
}

export default Agency;