import React from "react";

function InputWrapper(props) {
    const {title, children} = props;

    return (
        <>
            <div className="connect_form__block">
                <p className="section_block__title section_block__title--small" data-scroll="" data-scroll-fadeup="">{title}</p>
                {children}
            </div>
        </>
    )
}

export default InputWrapper;