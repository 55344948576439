import { useEffect } from "react";
import { useParams } from "react-router-dom";
import SimpleBar from "simplebar-react";
import Button from "../components/button/Button";
import PageTop from "../components/pageTop/PageTop";
import SortingFilters from "../components/sortingFilters/SortingFilters";
import ReviewCard from "../components/reviewCard/ReviewCard";
import { reviewsSorting } from "../data/sortingOptions";
import { getReviews } from "../api/server";
import { useState } from "react";
import { reviewsGradeFilter } from "../libs/frontFilters";
import { getTotalGradeReviews } from "../libs/getTotalGradeReviews";

function Reviews() {
    const [data, setData] = useState({});
    const [activeFilter, setActiveFilter] = useState(reviewsSorting[0]);
    const [isLoading, setIsLoading] = useState(false);
    const { id } = useParams();

    useEffect( _ => {
        const fetchData = async () => {

            const res = await getReviews(id);

            setData(res)
            setIsLoading(true)
        }

        fetchData();
    }, [])

    if (!isLoading) {
        return (
            <>
                <section className="section agencies">  
                    <div className="agencies__container agencies__container--center container">
                        <h1 className="title">Загрузка...</h1>
                    </div>
                </section>
            </>
        )
    } else {
        return (
            <>
                <section className="section reviews"> 
                    <div className="reviews__container container">
                        <PageTop
                            title={data.company.name}
                        >
                            <SortingFilters
                                options={reviewsSorting}
                                onClickFunction={setActiveFilter}
                            />
                        </PageTop>
                        <div className="reviews__content" data-scroll="" data-scroll-fadeup="">
                        <div className="agencies_card__bottom" data-scroll="" data-scroll-fadeup="">
                            <p className="agencies_card__bottom_link" href="/card"><span className="text">{reviewsGradeFilter(activeFilter, data).length} отзывов</span></p>
                            <div className="agencies_card__bottom_right">
                            <div className="agencies_card__like">
                                <div className="agencies_card__like_icon"> 
                                <svg className="icon_up">
                                    <use xlinkHref="#thumbs-up"></use>
                                </svg>
                                </div><span className="section_block__text">{getTotalGradeReviews(data.items, 1)}</span>
                            </div>
                            <div className="agencies_card__like">
                                <div className="agencies_card__like_icon"> 
                                <svg className="icon_down">
                                    <use xlinkHref="#thumbs-down"></use>
                                </svg>
                                </div><span className="section_block__text">{getTotalGradeReviews(data.items, 0)}</span>
                            </div>
                            </div>
                        </div>
                        <SimpleBar className="reviews__wrap" data-scroll="" data-scroll-fadeup="">
                            { reviewsGradeFilter(activeFilter, data).map( item => {
                                return (<ReviewCard key={item.id} data={item}/>)
                            })}
                        </SimpleBar>
                        <Button 
                            isLink = {true} 
                            href={`/reviews_form?company_id=${data.company.id}`}
                            customClass="card__btn"
                        >Оставить отзыв</Button>
                        </div>
                    </div>
                </section>
            </>
        )
    }
}

export default Reviews;