import React, { useState } from "react";
import InputMask from "react-input-mask";
import { maskObj } from "../../data/maskObj";

function Input(props) {
    const {name, required, title, type, defaultValue, disabled, mask} = props;

    const [isValid, setIsValid] = useState();
    const [isFocus, setIsFocus] = useState(false);
    const [inputValue, setInputValue] = useState(defaultValue);

    const onBlur = (evt) => {
        props.onBlur && props.onBlur()
        setIsValid(evt.target.validity.valid)
        setInputValue(evt.target.value);
        setIsFocus(false);
    }

    const onFocus = (evt) => {
        props.onFocus && props.onFocus(evt)
        setIsFocus(true)
    }

    const onInput = (evt) => {
        props.onInput && props.onInput(evt)
        setInputValue(evt.target.value);
    }

    return (
        <>
            <div className={`
                    input_wrapper input_wrapper--outlined ${isValid === false ? "not-valid" : ""} ${isFocus === true ? "on-focus" : ""}${disabled === true ? "is-disabled" : ""}`
                } 
                data-input-wrapper="" data-scroll="" data-scroll-fadeup="">
                <InputMask 
                    className={`input_wrapper__input ${inputValue ? "is-filled" : ""}`} 
                    type={type ? type : "text"}
                    name={name ? name : 'default'}
                    required={required ? required : true}
                    autoComplete="off"
                    value={inputValue ? inputValue : ""}
                    onInput={onInput}
                    onBlur={onBlur}
                    onFocus={onFocus}
                    mask={mask ? maskObj[mask] : false}
                />
                <label className="input_wrapper__label">{title}</label>
                <div className="input_wrapper__tooltip" data-input-tooltip=""></div>
            </div>
        </>
    )
}

export default Input;