import ResponceBlock from "../components/responceBlock/ResponceBlock";

function RequestSend() {

    return (
        <>
            <ResponceBlock/>
        </>
    )
}

export default RequestSend;