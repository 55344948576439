import React, { useState, useEffect } from "react";
import {useSelector} from "react-redux";
import Menu from "../menu/Menu";
import VideoModal from './modal/VideoModal';
import {closeModal} from "../../libs/modals";
import { useRef } from "react";

function Modals(props) {
    const {chldrend} = props;
    const [isActive, setIsActive] = useState(false);
    const modals = useSelector(state => state.modals);
    const ref = useRef();

    useEffect( _ => {
        if (modals) {
            setIsActive(true)
        } else {
            setIsActive(false)
        }
    }, [modals])


    // function modals() {
    //     if ()
    // }

    if (modals) {
        return (
            <>
                <div className={`modals ${isActive ? 'is-active' : ''}`} ref={ref}>
                    <div className="modal__overlay" onClick={_ => {closeModal(ref)}}></div>
                    {modals}
                </div>
            </>
        )
    }
}

export default Modals;