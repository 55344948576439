import * as Actions from "../actions/formAction";

const initialState = [];


export function agenciesUpdate(state = initialState, action) {
  switch (action.type) {
    case 'ADD_DATA':
      return state = action.value
    default:
      return state
  }
}