export async function createRequest(query) {
    const url = `${process.env.REACT_APP_API}/create_request`;

    const options = {
        method: "POST",
        mode: 'cors',
        headers: new Headers({'content-type': 'application/json'}),
        body: JSON.stringify(query)
    }

    const res = await fetch(url, options);
    const data = await res.json();

    return data;
}


export async function createReviews(query) {
    const url = `${process.env.REACT_APP_API}/create_reviews`;

    const options = {
        method: "POST",
        mode: 'cors',
        headers: new Headers({'content-type': 'application/json'}),
        body: JSON.stringify(query)
    }

    const res = await fetch(url, options);
    const data = await res.json();

    return data;
}

export async function connectRequest(query) {
    const url = `${process.env.REACT_APP_API}/connect_request`;

    const options = {
        method: "POST",
        mode: 'cors',
        headers: new Headers({'content-type': 'application/json'}),
        body: JSON.stringify(query)
    }

    const res = await fetch(url, options);
    const data = await res.json();

    return data;
}


