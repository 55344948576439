export const typeObject = [
    {
        key: "Квартира",
        value: "Квартира"
    },
    {
        key: "Дом / Коттедж",
        value: "Дом / Коттедж"
    },
    {
        key: "Бизнес-объект",
        value: "Бизнес-объект"
    },
    {
        key: "Гараж",
        value: "Гараж"
    },
    {
        key: "Гараж",
        value: "Гараж"
    },
    {
        key: "Гараж",
        value: "Гараж"
    },
]