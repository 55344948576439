import {store} from "../store/store";

export const openModal = (modalName) => {
    store.dispatch({
        type: 'OPEN_MODAL',
        value: modalName
    })
}

export const closeModal = (ref) => {
    if (ref.current) {
        ref.current.classList.remove('is-active');
    } else {
        ref.classList.remove('is-active');
    }

    setTimeout( _ => {
        store.dispatch({
            type: 'CLOSE_MODAL',
            value: ref
        })
    }, 500)
}