import React, {useState} from "react";
import Form from "../components/form/Form";
import InputWrapper from "../components/form/InputWrapper";
import Button from "../components/button/Button";
import PageTop from "../components/pageTop/PageTop";
import Input from "../components/input/Input";
import ResponceBlock from "../components/responceBlock/ResponceBlock";
import {createRequest} from "../api/forms";
import {store} from "../store/store"
import { formDataToJson } from "../libs/formDataToJson";

function ApplicationForm() {
    // const [params, setParams] = useSearchParams();
    const [resForm, setResForm] = useState();

    const formData = store.getState().form;
    

    const formSubmit = async (e) => {
        e.preventDefault();

        const formData = new FormData(e.target);
        const formDataJSON = formDataToJson(formData);
        const res = await createRequest(formDataJSON);


        setResForm(res);
    }

    if (resForm) {
        return(
            <>
                <ResponceBlock data={resForm}/>
            </>
        )
    } else {
        return (
            <>
                <section className="section card"> 
                    <div className="card__container container">
                        <PageTop
                            title="Создание заявки"
                        >
                        </PageTop>
                        <Form formSubmit={formSubmit}>
                            <input 
                                type="text"
                                name="agency"
                                value={`${formData.agency.name} - ${formData.agency.adress}`}
                                hidden={true}
                            />
                            <input 
                                type="text"
                                name="agency_price"
                                value={`${formData.agency.price}`}
                                hidden={true}
                            />
                            <InputWrapper
                                title="Адрес объекта"
                            >
                                <Input
                                    title="Ваш адрес"
                                    name="address"
                                    defaultValue={formData.adress}
                                    disabled={true}
                                />
                            </InputWrapper>
    
                            <InputWrapper
                                title="Что нужно охранять?"
                            >
                                <Input
                                    title="Ваш адрес"
                                    name="type_object"
                                    defaultValue={formData.type_object}
                                    disabled={true}
                                />
                            </InputWrapper>
    
                            <InputWrapper
                                title="Ваше имя"
                            >
                                <Input
                                    title="Введите имя"
                                    name="name"
                                />
                            </InputWrapper>
    
                            <InputWrapper
                                title="Номер телефона"
                            >
                                <Input
                                    title="+7 (999) 999-99-99"
                                    name="phone"
                                    type="tel"
                                    mask="phone"
                                />
                            </InputWrapper>
    
                            <InputWrapper
                                title="Электронная почта"
                            >
                                <Input
                                    title="mail@mail.ru"
                                    name="email"
                                    type="email"
                                />
                            </InputWrapper>
                            <Button 
                                customClass="connect_form__btn"
                            >Отправить заявку</Button>
                        </Form>
                    </div>
                </section>
            </>
        )
    }
}

export default ApplicationForm;