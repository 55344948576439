import { getAgency } from "../api/server";

export const reviewsGradeFilter = (activeFilter, data) => {
    console.log(data);
    if (activeFilter.key === null) {
        return data.items;
    }        

    const filterData = data.items.filter( item => {
        return item.grade == activeFilter.key;
    })

    return filterData
}

export const filterAgencies = (id, store) => {
    // console.log(store.getState().agencies);

    // if (store.getState().agencies.length == 0) {
    //     return getAgency(id);
    // } else {
        const agency = store.getState().agencies.filter( item => {
            return item.id == id
        });
        
        return agency[0];
    // }
}