import React from "react";

function ReviewCard (props) {
    const {data} = props;

    const date = new Date(data.created_at);

    const optionsDate = {
        year: 'numeric',
        month: 'long',
        day: 'numeric'
    };
      
    const optionsTime = {
        timezone: 'UTC',
        hour: 'numeric',
        minute: 'numeric'
    }

    return (
        <>
            <div className={`reviews_card ${+data.grade === 0 ? " reviews_card--down" : " reviews_card--up"}`}>
                <h3 className="section_block__title section_block__title--small">{data.name}</h3>
                <p className="section_block__text">{data.text}</p>
                <div className="reviews_card__bottom"> 
                    <p className="reviews_card__date">{date.toLocaleString("ru", optionsDate)} в {date.toLocaleString("ru", optionsTime)} </p>
                    {data.grade === 0 ? 
                        <div className="agencies_card__like_icon">
                            <svg className="icon_down">
                            <use xlinkHref="#thumbs-down"></use>
                            </svg>
                        </div>
                        :
                        <div className="agencies_card__like_icon">
                            <svg className="icon_up">
                                <use xlinkHref="#thumbs-up"></use>
                            </svg>
                        </div>
                    }
                </div>
            </div>
        </>
    )
}

export default ReviewCard;