import React, { useEffect, useState } from "react";
import {
  TransitionGroup,
  CSSTransition
} from "react-transition-group";
import {BrowserRouter as Router, Routes ,Route, useLocation } from 'react-router-dom';
import locomotiveScroll from "locomotive-scroll";
import Home from "./pages/Home";
import FormPage from "./pages/FormPage";
import Agencies from "./pages/Agencies";
import Layout from "./components/layouts/Layout";
import "./scss/main.scss"
import Agency from "./pages/Agency";
import ApplicationForm from "./pages/ApplicationForm";
import Payment from "./pages/Payment";
import RequestSend from "./pages/RequestSend";
import Reviews from "./pages/Reviews";
import ReviewsForm from "./pages/ReviewsForm";
import Connect from "./pages/Connect";
import {store} from "./store/store";



function App() {
  document.getElementById('root').classList.add('is-loaded');

  let location = useLocation();

  const scrollRef = React.createRef();

  useEffect(() => {
    const scroll = new locomotiveScroll();

    store.dispatch({
      type: 'CLOSE_MODAL',
      value: ""
    })
  });

  return (
      <Layout 
        ref={scrollRef}
        >
        <TransitionGroup>
          {/*
            This is no different than other usage of
            <CSSTransition>, just make sure to pass
            `location` to `Switch` so it can match
            the old location as it animates out.
          */}
          <CSSTransition
            key={location.pathname}
            classNames="is-loaded"
            timeout={300}
          >
              <Routes location={location}>
                <Route exact path="/" element={<Home/>}/>
                <Route exact path="/form" element={<FormPage/>}/>
                <Route exact path="/agencies" element={<Agencies/>}/>
                <Route exact path="/agencies/:id" element={<Agency/>}/>
                <Route exact path="/application_form" element={<ApplicationForm/>}/>
                <Route exact path="/request_send" element={<RequestSend/>}/>
                <Route exact path="/reviews/:id" element={<Reviews/>}/>
                <Route exact path="/reviews_form" element={<ReviewsForm/>}/>
                <Route exact path="/connect" element={<Connect/>}/>
                <Route exact path="/payment" element={<Payment/>}/>
              </Routes>
              </CSSTransition>
            </TransitionGroup>
      </Layout>
  );
}

export default App;
