import React, { useRef, useState, useEffect } from "react";
import { openModal } from "../libs/modals";
import Button from "../components/button/Button";
import InfoList from "../components/infoList/InfoList";
import {infoListMain} from "../data/infoListData";
import VideoModal from "../components/modals/modal/VideoModal";


function Home() {
    // const video = useRef();
    // const [videoPlay, setVideoPlay] = useState(false);

    // // video.current.addEventListener('fullscreenchange', (event) => {
    // //     console.log(event);
    // //     if (videoPlay) {
    // //         video.current.pause();
    // //         setVideoPlay(false);
    // //     }
    // // });

    // useEffect( _ => {
    //     // video.current.requestFullscreen().then( _ => {
    //     //     console.log(31231);
    //     // })
    //     // .catch(() => {
    //     //     screenChange();
    //     // });

    //     // video.current.addEventListener('fullscreenchange', (event) => {
    //     //     console.log(event);
    //     //     if (videoPlay) {
    //     //         video.current.pause();
    //     //         setVideoPlay(false);
    //     //     }
    //     // });
    // }, [video.current]);

    // function screenChange () {
    //     console.log('change');
    //     if (videoPlay) {
    //         video.current.pause();
    //         setVideoPlay(false);
    //     }
    // }
    

    // function openFullscreen(elem) {
    //     if (video.requestFullscreen) {
    //         elem.requestFullscreen();
    //     } else if (elem.webkitRequestFullscreen) { /* Safari */
    //         elem.webkitRequestFullscreen();
    //     } else if (elem.msRequestFullscreen) { /* IE11 */
    //         elem.msRequestFullscreen();
    //     }
    // }

    // function videoClick() {
    //     openFullscreen(video.current);
    //     video.current.play();
    //     setVideoPlay(true);
    // } 

    return (
        <>
            <section className="section main">
                <video className="image" src="/assets/img/nova_video.MP4" style={{display: 'none'}}></video>
                <img className="image" src="/assets/img/poster.png" style={{display: 'none'}}/>
                <div className="main__container container">
                    <div className="main__top" data-scroll="" data-scroll-fadeup="">
                        <div className="main__top_video" data-scroll="" data-scroll-fadeup="" onClick={_ => {openModal(<VideoModal/>)}}>
                            <img className="image" src="/assets/img/video_play.png"/>
                            <div className="video_play">
                                <svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <circle cx="32" cy="32" r="32" fill="#1089F9"/>
                                    <path d="M41.8999 29.4019C43.8999 30.5566 43.8999 33.4434 41.8999 34.5981L29.2999 41.8727C27.2999 43.0274 24.7999 41.584 24.7999 39.2746L24.7999 24.7254C24.7999 22.416 27.2999 20.9726 29.2999 22.1273L41.8999 29.4019Z" fill="white"/>
                                </svg>
                            </div>
                        </div>
                    </div>
                    <Button 
                        isLink = {true} 
                        href="/form"
                        customClass="main__btn"
                    >Узнать, кто быстрее</Button>
                    <InfoList
                        title="Вам нужны услуги охраны?"
                        data={infoListMain}
                    />
                </div>
            </section>
        </>
    )
}

export default Home;