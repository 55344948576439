import React, { useState } from "react";

function Form(props) {
    const {formName, children, formSubmit} = props;
    const [isLoading, setIsLoading] = useState(false);

    const onSubmit = async (e) => {
        setIsLoading(true);
    
        await formSubmit(e);

        setIsLoading(false)
    }

    return (
        <>
            <form 
                className={`form__form connect_form ${isLoading ? 'is-request' : ''}`} 
                action="/mailer" 
                data-mailer="" data-scroll="" data-scroll-fadeup=""
                onSubmit={e => {onSubmit(e)}}
                >
                <input type="hidden" name="form" value={formName}/>
                {children}
            </form>
        </>
    )
}

export default Form;