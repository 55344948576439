import React from "react";
import {Link} from "react-router-dom";
import {closeModal} from "../../libs/modals";

function Menu (props) {
    const {onClose} = props;

    return (
        <>
        <div className="modal_menu modal">
            <button className="modal_close modal_menu__close" onClick={_ => {closeModal(document.querySelector('.modals'))}} data-modal-close="data-modal-close">
                <span className="modal_menu__close_text">Закрыть</span>
                <svg className="icon">
                    <use xlinkHref="#close"></use>
                </svg>
            </button>
            <div className="modal_menu__logo">
                <img className="image" src="/assets/img/header-logo.svg" alt=""/>
            </div>
            <div className="modal_menu__block">
                <Link className="modal_menu__block_btn btn btn--green" to="/connect">
                    <span className="btn__text">Для охранных предприятий</span>
                </Link>
            </div>
            <div className="modal_menu__block">
                <Link className="modal_menu__block_btn btn btn--filled" to="/payment">
                    <span className="btn__text">Оплата</span>
                </Link>
            </div>
            <div className="modal_menu__socials">
                <a className="modal_menu__socials_link" href="tel:8 (800) 700-35-61">
                    <span className="modal_menu__socials_title">8 (800) 700-35-61</span>
                    <p className="modal_menu__socials_text">Бесплатный звонок по РФ</p>
                </a>
                <a className="modal_menu__socials_link" href="https://wa.me/79532155000">
                    <span className="modal_menu__socials_title">WhatsApp</span>
                    <p className="modal_menu__socials_text">Консультация в мессенджере</p>
                </a>
                <a className="modal_menu__socials_link" href="/">
                    <span className="modal_menu__socials_title">Telegram</span>
                    <p className="modal_menu__socials_text">Ответим на любой вопрос</p>
                </a>
                <a className="modal_menu__socials_link" href="mailto:ohrana-poisk@mail.ru">
                    <span className="modal_menu__socials_title">ohrana-poisk@mail.ru</span>
                    <p className="modal_menu__socials_text">Отправить запрос на почту</p>
                </a>
                <a className="modal_menu__socials_link" href="https://yandex.ru/maps/-/CCUJYLddHD">
                    <span className="modal_menu__socials_title">ул. Светланская 151б</span>
                    <p className="modal_menu__socials_text">Адрес офиса</p>
                </a>
            </div>
            <a className="itome" href="https://itome.online/" target="_blank" rel="noreferrer">
                <img className="image" src="/assets/img/itome.svg" alt=""/>
            </a>
        </div>
        </>
    )
}

export default Menu;