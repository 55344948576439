import React from "react";
import { Link } from "react-router-dom";

function Button(props) {
    const {event, isLink, href, children, customClass, onClick} = props
    const classString = `btn ${customClass}`;

    if (isLink) {
        return (
            <>
                <Link to={href ? href : "/"} className={`${classString} btn--filled`} data-scroll="" data-scroll-fadeup="">
                    <span className="btn__text">
                        {children}
                    </span>
                </Link>
            </>
        )
    }

    return (
        <>
            <button 
                className={`${classString} btn--filled`} 
                data-scroll="" 
                data-scroll-fadeup=""
                onClick={onClick}
                >
                <span className="btn__text">
                    {children}
                </span>
                <span className="is_loading">
                    <img src="/assets/img/loading_white.svg"/>
                </span>
            </button>
        </>
    )
}

export default Button = React.memo(Button);