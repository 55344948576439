import React, { useEffect, useState, useTransition } from "react";
import Header from "../header/Header";
import Modals from "../modals/Modals";

const Layout = React.forwardRef((props, ref) => {
    const [isLoad, setIsLoad] = useState(false)

    useEffect(_ => {
        setIsLoad(true);
    })

    return (
        <>
            <div className={`layout ${isLoad ? "is-loaded" : ""}`} ref={ref}>
                <Modals/>
                <Header/>
                <main className="view">
                    {props.children}
                </main>
            </div>
        </>
    )
})

export default Layout;