import React from "react";
import {Link} from "react-router-dom";
import LazyLoad from 'react-lazy-load';
import {store} from "../../store/store";
import {openModal} from "../../libs/modals";
import Menu from "../menu/Menu";

function Header(props) {


    return (
        <>
            <header className="header">
                <div className="container header__container">
                    <Link className="header__logo" to="/">
                        <LazyLoad>
                            <img className="image" src="/assets/img/header-logo.svg" alt="" data-lazy=""/>
                        </LazyLoad>
                    </Link>
                    <button className="header__menu" onClick={_ => {openModal(<Menu/>)}} data-linked-modal="menu">
                        <span className="header__menu_text">Меню</span>
                        <svg className="icon">
                            <use xlinkHref="#menu"></use>
                        </svg>
                    </button>
                </div>
            </header>
        </>
    )
}

export default Header;