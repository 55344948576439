import React, { useEffect, useState } from "react";
import { useDebounce } from "use-lodash-debounce";
import { Navigate, useNavigate, useSearchParams  } from "react-router-dom";
import LazyLoad from "react-lazy-load";
import Button from "../components/button/Button";
import Form from "../components/form/Form";
import InputWrapper from "../components/form/InputWrapper";
import DropdownSearch from "../components/dropdownSearch/DropdownSearch";
import Dropdown from "../components/dropdown/Dropdown";
import Input from "../components/input/Input";
import {typeObject} from "../data/dropdownOptions";
import InfoList from "../components/infoList/InfoList";
import {infoListMain} from "../data/infoListData";
import {getClientPosition} from "../api/dadata";
import { store } from "../store/store";
import { formDataToJson } from "../libs/formDataToJson";

function FormPage() {
    const [locationValue, setLocationValue] = useState("");
    const [locationOptions, setLocationOptions] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const debunceLocationValue = useDebounce(locationValue, 1000);
    const [params, setParams] = useSearchParams();
    let navigate = useNavigate();

    useEffect(_ => {
        setLocationOptions(generateLocatioOptions(debunceLocationValue));
    }, [debunceLocationValue])


    const locationOptionsChangeInput = (value) => { 
        setIsLoading(value.value === locationValue ? false : true);
        setLocationValue(value.value);
    }



    let adressActive,
        typeObjectActive;

    if (store.getState().form.state !== "Init") {
        adressActive = {
            key: store.getState().form.adress__hidden,
            value: store.getState().form.adress
        }

        typeObjectActive = {
            key: store.getState().form.type_object,
            value: store.getState().form.type_object__hidden
        }
    }

    const generateLocatioOptions = async (query) => {
        const data = await getClientPosition(query);
        setIsLoading(false);

        const options = data.suggestions.map((item, index) => {
            const startFilledIndex = item.value.toLowerCase().indexOf(query.toLowerCase());
            const queryLength = query.length;
            const fillStr = item.value.substr(startFilledIndex, startFilledIndex + queryLength);
            const newStr = `<span class="black">${fillStr}</span>`;
            const valueFill = item.value.replace(fillStr, newStr);

            return {
                key: [item.data.geo_lon, item.data.geo_lat],
                valueFill: valueFill,
                value: item.value
            }
        })

        setLocationOptions(options);
    }

    const formSubmit = (e) => {
        e.preventDefault();
        
        const formData = new FormData(e.target);
        const formDataJSON = formDataToJson(formData);

        if (formDataJSON.adress__hidden === 'write') {
            alert('Введенный адрес не найден.');

            return;
        }

        store.dispatch({
            type: 'ADD_VALUES',
            value: formDataJSON
        })

        store.dispatch({
            type: 'ADD_DATA',
            value: []
        })    

        navigate(`/agencies?${params.toString()}`);
    }

    return (
        <>
            <section className="section main">
                <div className="main__container container">
                    <InfoList
                        title="Вам нужны услуги охраны?"
                        data={infoListMain}
                    />
                    <Form formSubmit={formSubmit}>
                        <InputWrapper
                            title="Укажите адрес объекта"
                        >
                            <DropdownSearch
                                title="Ваш адрес"
                                name="adress"
                                readonly={false}
                                options={locationOptions}
                                value={locationValue}
                                onChangeInput={locationOptionsChangeInput}
                                isLoading={isLoading}
                                activeOptionIndex={adressActive ? adressActive : ""}
                            />
                        </InputWrapper>
                        <InputWrapper
                            title="Что нужно охранять?"
                        >
                            <Dropdown
                                title="Тип объекта"
                                name="type_object"
                                options={typeObject}
                                activeOptionIndex={typeObjectActive ? typeObjectActive : ""}
                            />
                        </InputWrapper>
                        <Button 
                            customClass="connect_form__btn"
                        >Узнать, кто быстрее</Button>
                    </Form>
                </div>
            </section>
        </>
    )
}

export default FormPage;