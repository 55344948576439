export const agencySorting = [
    {
        id: 1,
        title: "по цене",
        key: "price.asc"
    },
    {
        id: 2,
        title: "по скорости",
        key: "distance.asc"
    },
    {
        id: 3,
        title: "по рейтингу",
        key: "rating.desc"
    },
]

export const reviewsSorting = [
    {
        id: 1,
        title: "Все",
        key: null
    },
    {
        id: 2,
        title: "Положительные",
        key: 1
    },
    {
        id: 3,
        title: "Отрицательные",
        key: 0
    },
]