import React, { useState } from "react";

function Textarea(props) {
    const {name, required, title, defaultValue, disabled} = props;

    const [isValid, setIsValid] = useState();
    const [isFocus, setIsFocus] = useState(false);
    const [inputValue, setInputValue] = useState(defaultValue);

    const onBlur = ({target}) => {
        setIsValid(target.validity.valid)
        setInputValue(target.value);
        setIsFocus(false);
    }

    const onFocus = () => {
        setIsFocus(true)
    }

    const onInput = (e) => {
        setInputValue(e.target.value);
    }

    return (
        <>
            <div className={`
                    input_wrapper input_wrapper--outlined ${isValid === false ? "not-valid" : ""} ${isFocus === true ? "on-focus" : ""}${disabled === true ? "is-disabled" : ""}`
                } 
                data-input-wrapper="" data-scroll="" data-scroll-fadeup="">
                <textarea 
                    className={`input_wrapper__input ${inputValue ? "is-filled" : ""}`} 
                    type="textarea"
                    name={name ? name : 'default'}
                    required={required ? required : true}
                    autoComplete="off"
                    disabled={disabled ? disabled : false}
                    value={inputValue ? inputValue : ""}
                    
                    onInput={onInput}
                    onBlur={onBlur}
                    onFocus={onFocus}
                />
                <label className="input_wrapper__label">{title}</label>
                <div className="input_wrapper__tooltip" data-input-tooltip=""></div>
            </div>
        </>
    )
}

export default Textarea;