import React from "react";
import { useNavigate } from "react-router-dom";

function PageTop(props) {
    const {title, children} = props;
    const history = useNavigate();

    const goBack = () => {
        history(-1);
    }
    

    return (
        <>
            <div className="page_top" data-scroll="" data-scroll-fadeup="">
                <div className="page_top__upper" data-scroll="" data-scroll-fadeup="">
                    <h2 className="section_block__title">{title}</h2>
                    <button className="page_top__arrow" onClick={goBack}>
                        <svg className="icon">
                            <use xlinkHref="#arrow"></use>
                        </svg>
                    </button>
                </div>

                {children}
            </div>
        </>
    )
}

export default PageTop = React.memo(PageTop);