import React from "react";
import Button from "../button/Button";

function ResponceBlock (props) {
    const {data} = props;

    return (
        <section className="section request_send">
            <div className="connect_send__container container" data-scroll="" data-scroll-fadeup="">
                <div className="connect_send__icon" data-scroll="" data-scroll-fadeup="">
                    <img className="image" src="/assets/img/request-pic.svg" alt=""/>
                </div>
                <div className="section_block">
                    <h2 className="section_block__title" data-scroll="" data-scroll-fadeup="">{data.info.title}</h2>
                    <p 
                        className="section_block__text" 
                        data-scroll="" 
                        data-scroll-fadeup=""
                        dangerouslySetInnerHTML={{__html: data.info.text}}
                        ></p>
                </div>
                <Button
                    isLink={true}
                    href="/"
                    customClass="request_send__btn"
                >
                    Вернуться на главную
                </Button>
            </div>
        </section>
    )
}

export default ResponceBlock;