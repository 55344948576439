import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import SimpleBar from 'simplebar-react';
import AgencyCard from "../components/agencyCard/AgencyCard";
import Button from "../components/button/Button";
import PageTop from "../components/pageTop/PageTop";
import SortingFilters from "../components/sortingFilters/SortingFilters";
import {agencySorting} from "../data/sortingOptions";
import {getAgencies} from "../api/server";
import { store } from "../store/store";
import { getOrderBy } from "../libs/sorting";


function Agencies() {
    const [data, setData] = useState();
    const [isLoading, setIsLoading] = useState(false);
    const [filter, setFilter] = useState({});
    const [viewMoreIsVisible, setViewMoreIsVisible] = useState(true);
    const navigate = useNavigate();

    useEffect( _ => {
        if (store.getState().form.state === "Init") {
            navigate(`/form`);

            return
        }

        const fetchData = async () => {
            // if (store.getState().agencies.length === 0) {

            setIsLoading(false);

            const query = store.getState().form;

            const res = await getAgencies({...query, ...filter});

            setData(res);

            store.dispatch({
                type: 'ADD_DATA',
                value: res
            })

            setIsLoading(true);
        }

        fetchData();
    }, [filter]);

    const sortingOnChange = (e) => {
        setFilter({...filter, ...getOrderBy(e)});
    }

    const viewMore = (e) => {
        setFilter({...filter, ...{limit:20}});

        setViewMoreIsVisible(false);
    }


    if (store.getState().form.state === "Init") {
        return (
            <>
                <section className="section agencies">  
                    <div className="agencies__container agencies__container--center container">
                        <h1 className="title">Не выбраны параметры для поиска</h1>
                    </div>
                </section>
            </>
        )
    } else if (data && isLoading) {
        if (data.length !== 0) {
            return (
                <>
                    <section className="section agencies"> 
                        
                        <div className="agencies__container container">
                            <PageTop
                                title="Охранные агентства"
                            >
                                <SortingFilters
                                    options={agencySorting}
                                    onClickFunction={sortingOnChange}
                                />
                            </PageTop>
                            <div className="agencies__content">
                                {data.map(item => {
                                    return <AgencyCard key={item.id} data={item}/>
                                })}
                            </div>
                            {viewMoreIsVisible ?
                                <Button 
                                    customClass="agencies__btn"
                                    onClick={viewMore}
                                >
                                    Показать еще
                                </Button>
                                :
                                ""  
                            }
                        
                        </div>
                    </section>
                </>
            )
        } else {
            return (
                <>
                    <section className="section agencies">  
                        <div className="agencies__container agencies__container--center container">
                            <h1 className="title">Охранные агенства по близости не найдены</h1>
                            <Button 
                                customClass="agencies__btn"
                                onClick={_ => navigate(`/form`)}
                            >
                                Вернуться назад
                            </Button>
                        </div>
                    </section>
                </>
            )
        }
    } else {
        return (
            <>
                <section className="section agencies">  
                    <div className="agencies__container container">
                        <PageTop
                            title="Охранные агентства"
                        >
                            <SortingFilters
                                options={agencySorting}
                                onClickFunction={sortingOnChange}
                            />
                        </PageTop>
                        <div className="agencies__content">
                            <h1 className="title download_text">Загрузка...</h1>
                        </div>
                    </div>
                </section>
            </>
        )
    }
}

export default Agencies;