import React, { useState, useEffect, useRef } from "react";
import SimpleBar from "simplebar-react";

function DropdownSearch(props) {
    let {name, required, title, type, options, activeOptionIndex, readonly, onChangeInput, value, isLoading} = props;
    onChangeInput = onChangeInput ? onChangeInput : _ => {}


    const [activeOption, setActiveOption] = useState(activeOptionIndex ? activeOptionIndex : value || "");
    const [isOpen, setIsOpen] = useState(false);
    const [wrapperHeight, setWrapperHeight] = useState();
    const [optionHeight, setOptionHeight] = useState();
    const [wrapperCurrentHeight, setWrapperCurrentHeight] = useState();

    const rootEl = useRef(null);
    const wrapperEl = useRef(null);
    const optionEl = useRef(null);

    useEffect(() => {
        const onClick = e => rootEl.current.contains(e.target) ||  toggleState();
        document.addEventListener('click', onClick);
        return () => document.removeEventListener('click', onClick);
      });

    useEffect(() => {
        setWrapperHeight(wrapperEl.current.getBoundingClientRect().height);
        setOptionHeight(optionEl.current ? optionEl.current.getBoundingClientRect().height : 0);

        setWrapperCurrentHeight(_ => setHeightWrapper(isOpen));
    }, [options]);

      
    const toggleState =(open = false) => {
        setIsOpen(open)
        setWrapperCurrentHeight(_ => setHeightWrapper(open));
    }

    const setOption = (optionIndex, e) => {
        e.preventDefault();
        
        toggleState(false);
        setActiveOption(options[optionIndex]);
        onChangeInput(options[optionIndex]);
    }

    const setHeightWrapper = (open) => {
        if (open) {
            if (options.length < 4) {
                return (wrapperHeight + (optionHeight * options.length)) + "px";
            } else {
                return (wrapperHeight + (optionHeight * 4)) + "px";
            }
        } else {
            return wrapperHeight + "px";
        }
    };

    const onFocus = (e) => {

    }

    const updateInputValue = (e) => {
        const value = e.target.value;

        setActiveOption({
            key: "write",
            value: value
        })

        onChangeInput(activeOption);
    }

    let style = {
        height: wrapperCurrentHeight
    };

    const OptionsHtml = () => {
        if (isLoading === true) {
            return (
                <div className="dropdown__option dropdown__option--loading">
                    <img className="image image" src="/assets/img/loading.svg" alt="loading"/>
                </div>
            )
        } else if (options && options.length > 0) {
            return (
                options.map((option, index) => {
                    return (
                        <button ref={optionEl}
                        className={`dropdown__option ${(activeOption && activeOption === option) ? "is-active" : ""}`}
                        key={index} 
                        onClick={e => {setOption(index, e)}}
                        dangerouslySetInnerHTML={{__html: option.valueFill}}
                        >
                        </button>
                    )
                })
            )
        } else {
            return (
                <div className="dropdown__option">
                    <p>Введите значение</p>
                </div>
            )
        }
    };

    return (
        <>
            <div className={`dropdown ${isOpen ? " is-open" : ""}`} data-input-wrapper="" data-scroll="" data-scroll-fadeup="" ref={rootEl}>
                <div className="dropdown__wrapper" ref={wrapperEl}>
                    <input 
                        className="dropdown__input" 
                        type="text"
                        placeholder={title}
                        name={name ? name : 'default'}
                        required={required ? required : true}
                        autoComplete="off"
                        value={activeOption ? activeOption.value : ""}
                        readOnly={readonly === false ? false : true}
                        onChange={e => {
                            updateInputValue(e)
                            onChangeInput(activeOption)
                        }}
                        onClick={_ => {toggleState(!isOpen)}}
                        onFocus={readonly === false ? e => {onFocus(e)} : _ => {}}
                    />
                    <input 
                        type="text"
                        name={name ? `${name}__hidden` : 'default__hidden'}
                        value={activeOption ? activeOption.key : ""}
                        hidden
                        onChange={_ => {}}
                    />
                    <div className="dropdown__arrow">
                        <svg className="icon">
                            <use xlinkHref="#arrow_dropdown"></use>
                        </svg>
                    </div>
                    <SimpleBar 
                        className="dropdown__options"
                    >
                    {
                        <OptionsHtml/>
                    }
                    </SimpleBar>
                </div>
            </div>
        </>
    )
}

export default DropdownSearch;