import React, {useState} from "react";
import Form from "../components/form/Form";
import InputWrapper from "../components/form/InputWrapper";
import Button from "../components/button/Button";
import PageTop from "../components/pageTop/PageTop";
import Input from "../components/input/Input";
import ResponceBlock from "../components/responceBlock/ResponceBlock";
import Textarea from "../components/textarea/Textarea";
import {connectRequest} from "../api/forms";
import {formDataToJson} from "../libs/formDataToJson";

function Connect() {
    const [resForm, setResForm] = useState(false);

    const formSubmit = async (e) => {
        e.preventDefault();

        const formData = new FormData(e.target);
        const formDataJSON = formDataToJson(formData);

        
        const res = await connectRequest(formDataJSON);
        setResForm(res);
    }

    if (resForm) {
        return(
            <>
                <ResponceBlock data={resForm}/>
            </>
        )
    } else {
        return (
            <>
                <section className="section card"> 
                    <div className="card__container container">
                        <PageTop
                            title="Подключиться к сервису"
                        >
                        </PageTop>
                        <Form formSubmit={formSubmit}>
                            <InputWrapper
                                title="Ваше имя"
                            >
                                <Input
                                    title="Введите имя"
                                    name="name"
                                />
                            </InputWrapper>
    
                            <InputWrapper
                                title="Номер телефона"
                            >
                                <Input
                                    title="Введите номер"
                                    name="phone"
                                    type="tel"
                                />
                            </InputWrapper>

                            <InputWrapper
                                title="О вашей компании"
                            >
                                <Textarea
                                    title="Расскажите что-нибудь"
                                    name="text"
                                />
                            </InputWrapper>

                            <Button 
                                customClass="connect_form__btn"
                            >Подключиться к нам</Button>
                        </Form>
                    </div>
                </section>
            </>
        )
    }
}

export default Connect;