export async function getAgencies(query) {
    const url = `${process.env.REACT_APP_API}/agencies`;

    const options = {
        method: "POST",
        mode: 'cors',
        headers: new Headers({'content-type': 'application/json'}),
        body: JSON.stringify(query)
    }

    return await fetch(url, options)
        .then( res => {
            return res.json()
        })
        .then( data => {
            return data
        })
        .catch( err => {
            return [];
        })
}

export async function getAgency(id) {
    const url = `${process.env.REACT_APP_API}/agencies/${id}`;

    const options = {
        method: "POST",
        mode: 'cors',
        headers: new Headers({'content-type': 'application/json'}),
    }

    return await fetch(url, options)
        .then( res => {
            return res.json()
        })
        .then( data => {
            return data
        })
}


export async function getReviews(companyId, query = {}) {
    const url = `${process.env.REACT_APP_API}/reviews/${companyId}`;

    const options = {
        method: "POST",
        mode: 'cors',
        headers: new Headers({'content-type': 'application/json'}),
        body: JSON.stringify(query)
    }

    return await fetch(url, options)
        .then( res => {
            return res.json()
        })
        .then( data => {
            return data
        })
}