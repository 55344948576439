import React from "react";
import { useEffect } from "react";
import { useRef } from "react";
import { closeModal } from "../../../libs/modals";
import RatingListItem from "../../ratingListItem/RatingListItem";

function RatingModal(props) {
    const { data, onClose } = props;
    
    const guaranteeEl = () => {
        if (data.rating >= 10) {
            return (
                <>  
                    <div class="rating_modal__check">
                        <img className="image" src="/assets/img/check_1.svg" alt=""/>
                        <div className="rating_modal__right">
                            <h4 className="rating_modal__check_title title">100% гарантия</h4>
                            <p className="rating_modal__check_text">Охрана Поиск рекомендует данную организацию</p>
                        </div>
                    </div>
                    <div className="line"></div>
                    <p class="rating_modal__small_text">
                        Сервис Охрана Поиск даёт гарантию качества работы данного частного охранного предприятия при наличии исправного оборудования на объекте заказчика охранных услуг. Сервис
                        в праве пересмотреть оценку в случае получения подтвержденных негативных отзывов от пользователей, либо при осуществлении плановой проверки работы частного охранного предприятия.
                    </p>
                </>
            )
        } else {
            return (
                <>  
                    <div class="rating_modal__check">
                        <img className="image" src="/assets/img/check_2.svg" alt=""/>
                        <div className="rating_modal__right">
                            <h4 className="rating_modal__check_title title">Нет гарантии</h4>
                            <p className="rating_modal__check_text">Охрана Поиск не дает гарантию качества данной организации</p>
                        </div>
                    </div>
                    <div className="line"></div>
                    <p class="rating_modal__small_text">
                        Сервис Охрана Поиск не даёт гарантию качества работы данного частного охранного предприятия.
                    </p>
                </>
            )
        }
    }

    console.log(data);
    
    return (
        <>
            <div className="rating_modal modal">
                <button className="modal__close" onClick={_ => {closeModal(document.querySelector('.modals'))}}>
                    <svg className="icon" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fillRule="evenodd" clipRule="evenodd" d="M16.7075 7.29226C16.3174 6.90221 15.685 6.90226 15.2949 7.29238L12 10.5873L8.76841 7.35574C8.37835 6.96568 7.74589 6.96574 7.35577 7.35586C6.96565 7.74598 6.9656 8.37844 7.35565 8.76849L10.5872 12L7.29262 15.2946C6.9025 15.6847 6.90245 16.3172 7.29251 16.7073C7.68256 17.0973 8.31502 17.0973 8.70514 16.7071L11.9997 13.4126L15.2316 16.6444C15.6216 17.0344 16.2541 17.0344 16.6442 16.6443C17.0343 16.2542 17.0344 15.6217 16.6443 15.2316L13.4125 11.9998L16.7074 8.7049C17.0975 8.31478 17.0976 7.68232 16.7075 7.29226Z"/>
                    </svg>
                </button>
                <div className="rating_modal__header">
                    <h3 className="rating_modal__title title">
                        Диагностическая карта
                        <span className="blue"> №{data.card_num}</span>
                    </h3>
                    <p className="rating_modal__date">
                        Дата проверки:
                        <span className="strong"> {new Date(data.card_time).toLocaleDateString()}</span>
                    </p>
                </div>
                <div className="line"></div>
                <ul className="rating_modal__list">
                    <RatingListItem
                        title="Наличие собственных ГБР"
                        status={data.have_gbr === 1 ? true : false}
                    />
                    <RatingListItem
                        title="Наличие оружия и спец. средств"
                        status={data.have_gun === 1 ? true : false}
                    />
                    <RatingListItem
                        title="Более трёх ГБР на линии"
                        status={data.three_bgr_on_line === 1 ? true : false}
                    />
                    <RatingListItem
                        title="Страховой полис"
                        status={data.have_policy === 1 ? true : false}
                    />
                    <RatingListItem
                        title="Лицензированные охранники"
                        status={data.security_license === 1 ? true : false}
                    />
                    <RatingListItem
                        title="Оценка контрольной сработки"
                        statusText={`${data.rating}/10`}
                        statusColor="blue"
                    />
                </ul>
                <div className="line"></div>
                {guaranteeEl()}
            </div>
        </>
    )
}

export default RatingModal;