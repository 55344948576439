import React, {useState} from "react";
import {
    useLocation
  } from "react-router-dom";
import Form from "../components/form/Form";
import InputWrapper from "../components/form/InputWrapper";
import Button from "../components/button/Button";
import PageTop from "../components/pageTop/PageTop";
import Input from "../components/input/Input";
import ResponceBlock from "../components/responceBlock/ResponceBlock";
import Textarea from "../components/textarea/Textarea";
import {createReviews} from "../api/forms";
import {formDataToJson} from "../libs/formDataToJson";

function ReviewsForm() {
    const [resForm, setResForm] = useState(false);
    const { search } = useLocation();

    const queryParams = (search) => {
        return new URLSearchParams(search);
    }

    const formSubmit = async (e) => {
        e.preventDefault();

        const formData = new FormData(e.target);
        const formDataJSON = formDataToJson(formData);

        formDataJSON['company_id'] = queryParams(search).get('company_id');

        
        const res = await createReviews(formDataJSON);
        setResForm(res);
    }

    if (resForm) {
        return(
            <>
                <ResponceBlock data={resForm}/>
            </>
        )
    } else {
        return (
            <>
                <section className="section card"> 
                    <div className="card__container container">
                        <PageTop
                            title="Создание заявки"
                        >
                        </PageTop>
                        <Form formSubmit={formSubmit}>
                            <InputWrapper
                                title="Ваше имя"
                            >
                                <Input
                                    title="Введите имя"
                                    name="name"
                                />
                            </InputWrapper>
    
                            <InputWrapper
                                title="Номер телефона"
                            >
                                <Input
                                    title="Введите номер"
                                    name="phone"
                                    type="tel"
                                    mask="phone"
                                />
                            </InputWrapper>

                            <InputWrapper
                                title="Отзыв"
                            >
                                <Textarea
                                    title="Расскажите что-нибудь"
                                    name="text"
                                />
                            </InputWrapper>

                            <Button 
                                customClass="connect_form__btn"
                            >Оставить отзыв</Button>
                        </Form>
                    </div>
                </section>
            </>
        )
    }
}

export default ReviewsForm;