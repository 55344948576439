import React from "react";

function RatingListItem (props) {
    const { title, statusText, statusColor, status } = props;
    let text = "";
    const statusObj = {};

    console.log(status);

    if (status !== undefined && status !== null) {
        if (status === true) {
            statusObj.text = "Да"
            statusObj.color = "blue"
        } else {
            statusObj.text = "Нет"
            statusObj.color = "grey"
        }
    } else {
        statusObj.text = statusText;
        statusObj.color = "blue"
    }

    return (
        <li className="rating_modal__list_item rating_modal_list_item">
            <p className="rating_modal_list_item__title">
              {title}:
              <span class={statusObj.color}> {statusObj.text}</span>  
            </p>
        </li>
    )
}

export default RatingListItem;