import React from "react";
import { useEffect } from "react";
import { useRef } from "react";
import { closeModal } from "../../../libs/modals";

function VideoModal(props) {
    const {onClose} = props;
    const video = useRef();

    useEffect( _ => {
        if (video) {
            video.current.play();
        }
    }, [video])

    return (
        <>
            <div className="video_modal modal">
                <button className="modal__close" onClick={_ => {closeModal(document.querySelector('.modals'))}}>
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fillRule="evenodd" clipRule="evenodd" d="M16.7075 7.29226C16.3174 6.90221 15.685 6.90226 15.2949 7.29238L12 10.5873L8.76841 7.35574C8.37835 6.96568 7.74589 6.96574 7.35577 7.35586C6.96565 7.74598 6.9656 8.37844 7.35565 8.76849L10.5872 12L7.29262 15.2946C6.9025 15.6847 6.90245 16.3172 7.29251 16.7073C7.68256 17.0973 8.31502 17.0973 8.70514 16.7071L11.9997 13.4126L15.2316 16.6444C15.6216 17.0344 16.2541 17.0344 16.6442 16.6443C17.0343 16.2542 17.0344 15.6217 16.6443 15.2316L13.4125 11.9998L16.7074 8.7049C17.0975 8.31478 17.0976 7.68232 16.7075 7.29226Z" fill="#fff"/>
                    </svg>
                </button>
                <video ref={video} className="image" src="/assets/img/nova_video.MP4" poster="/assets/img/poster.png" controls={true}></video>
            </div>
        </>
    )
}

export default VideoModal;