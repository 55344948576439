import * as Actions from "../actions/formAction";

const initialState = "";


export function modalReducer(state = initialState, action) {
  switch (action.type) {
    case 'OPEN_MODAL':
      return state = action.value
    case 'CLOSE_MODAL':
        return state = ""
    default:
      return state
  }
}