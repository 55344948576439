export const infoListMain = [
    {
        text: 'Просто введите свой адрес'
    },
    {
        text: 'Выберите организацию'
    },
    {
        text: 'Оставьте заявку'
    },
    {
        text: 'Получите 100% безопасность'
    },
]