export async function getClientPosition(query) {
    const url = "https://suggestions.dadata.ru/suggestions/api/4_1/rs/suggest/address";
    const  token = "f09a88b0c22337316bd491658f31be382092502b";

    const options = {
        method: "POST",
        mode: "cors",
        headers: {
            "Content-Type": "application/json",
            "Accept": "application/json",
            "Authorization": "Token " + token
        },
        body: JSON.stringify({
            query: query,
            "locations_boost": [{
                "kladr_id": 2500000100000
            }]
        })
    }

    return await fetch(url, options)
        .then( res => {
            return res.json()
        })
        .then( data => {
            return data
        })
    // const data = await res.then( responce => {
    //     return responce.json();
    // })
}