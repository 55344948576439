import React, { useEffect, useState } from 'react';
import Form from '../components/form/Form';
import InputWrapper from '../components/form/InputWrapper';
import Button from '../components/button/Button';
import PageTop from '../components/pageTop/PageTop';
import Input from '../components/input/Input';
import ResponceBlock from '../components/responceBlock/ResponceBlock';
import Textarea from '../components/textarea/Textarea';
import { connectRequest } from '../api/forms';
import { formDataToJson } from '../libs/formDataToJson';
import Checkbox from '../components/checkbox/checkbox';

function Payment() {
	const [resForm, setResForm] = useState(false);
	const [formData, setFormData] = useState({});

	const formSubmit = async e => {
		e.preventDefault();

		console.log(formData.sum, formData.email, formData.contract);
		var widget = new window.cp.CloudPayments();

		var paymentData = {};
		if (formData.regular)  {
			paymentData.CloudPayments = {
				recurrent: {
					interval: 'Month',
					period: 1,
				},
			}; 
		}

		widget.pay(
			'auth', // или 'charge'
			{
				publicId: 'pk_3ae45ec8ef83a12b93fb3d6339624', 
				description: `Оплата по договору ${formData.contract}`, 
				amount: parseInt(formData.sum), 
				currency: 'RUB', 
				accountId: formData.email, 
				email: formData.email, 
				skin: 'mini', 
				data: paymentData
			},
			{
				onSuccess: function (options) {
					
					setResForm({
						info: {
							title: 'Успешно!',
							text: 'Оплата проведена успешно',
						},
					});
				},
				onFail: function (reason, options) {
					// fail
					setResForm({
						info: {
							title: 'Ошибка',
							text: 'При оплате произошла ошибка. Попробуйте ещё раз',
						},
					});
				},
			}
		);
	};

	const handleInput = evt => {
		const input = evt.target;
		if (
			(input.type === 'checkbox' && input.checked) ||
			input.type !== 'checkbox'
		) {
			setFormData({
				...formData,
				[input.name]: input.value,
			});
		} else {
			const newFormData = {};
			Object.keys(formData)
				.filter(key => key !== input.name)
				.map(key => (newFormData[key] = formData[key]));

			setFormData(newFormData);
		}
	};

	useEffect(() => {
		const script = document.createElement('script');

		script.src = 'https://widget.cloudpayments.ru/bundles/cloudpayments.js';
		script.async = true;

		document.body.appendChild(script);

		return () => {
			document.body.removeChild(script);
		};
	}, []);

	if (resForm) {
		return (
			<>
				<ResponceBlock data={resForm} />
			</>
		);
	} else {
		return (
			<>
				<section className="section card">
					<div className="card__container container">
						<PageTop title="Оплата по договору"></PageTop>
						<Form formSubmit={formSubmit}>
							<InputWrapper title="Ваш email">
								<Input
									title="Введите email"
									name="email"
									type="email"
									required={true}
									onInput={handleInput}
								/>
							</InputWrapper>

							<InputWrapper title="Номер договора">
								<Input
									title="Введите номер договора"
									name="contract"
									type="text"
									onInput={handleInput}
								/>
							</InputWrapper>

							<InputWrapper title="Сумма оплаты">
								<Input
									title="Введите сумму"
									name="sum"
									type="number"
									onInput={handleInput}
								/>
							</InputWrapper>

							<br />
							<InputWrapper>
								<Checkbox
									id="regular"
									name="regular"
									title="Подключить автоплатёж"
									onInput={handleInput}
								>
									Данная сумма раз в месяц будет списываться с
									вашей карты. <br />В любой момент вы сможете
									<a
										className="inline_link"
										href="https://my.cloudpayments.ru/ru/unsubscribe"
										target="_blank"
									>
										отключить ежемесячный платёж
									</a>
								</Checkbox>
							</InputWrapper>

							<Button customClass="connect_form__btn">
								Перейти к оплате
							</Button>
						</Form>
					</div>
				</section>
			</>
		);
	}
}

export default Payment;
