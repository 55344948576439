import React from "react";
import { Link } from "react-router-dom";
import LazyLoad from "react-lazy-load";
import Rating from "../rating/Rating";
import { openModal } from "../../libs/modals";
import RatingModal from "../modals/modal/RatingModal";

function AgencyCard(props) {
    const { data } = props;

    function getReviewsTotal (total) {
        if (total === 1) {
            return "1 отзыв"
        } else if (total > 1 && total <= 4) {
            return `${total} отзыва`
        } else if (total >= 5){
            return `${total} отзывов`
        } else {
            return `Отзывы`
        }
    }

    const openRatingModal = (e) => {
        e.preventDefault();

        openModal(<RatingModal data={data}/>);
    }
    
    return (
        <>
            <div className="agencies__wrap" data-scroll="" data-scroll-fadeup="">
                <Link className="agencies__card agencies_card" to={`/agencies/${data.id}`} data-scroll="" data-scroll-fadeup="">
                    <div className="agencies_card__top" data-scroll="" data-scroll-fadeup="">
                        <div className="agencies_card__top_icon"> 
                            <LazyLoad>
                                <img className="image" src={data.image.path} alt={data.name}/>
                            </LazyLoad>
                        </div>
                        <div className="agencies_card__top_info">
                            <h3 className="section_block__title">{data.name}</h3>
                            <p className="section_block__text">{data.adress}</p>
                        </div>
                    </div>
                    <div className="agencies_card__line" data-scroll="" data-scroll-fadeup="">
                        <div className="agencies_card__block">
                            <div className="agencies_card__block_icon"> 
                                <svg className="icon">
                                    <use xlinkHref="#clock"></use>
                                </svg>
                            </div>
                            <p className="agencies_card__block_text">
                                {Math.round(data.mapData.routes[0].duration / 60)} минут
                            </p>
                        </div>
                        <div className="agencies_card__block">
                            <div className="agencies_card__block_icon"> 
                                <svg className="icon">
                                    <use xlinkHref="#ruble"></use>
                                </svg>
                            </div>
                            <p className="agencies_card__block_text">от {new Intl.NumberFormat('ru-RU').format(data.price)} ₽ в месяц</p>
                        </div>
                        <div className="agencies_card__block">
                            <Rating
                                rating={data.rating}
                                onClick={openRatingModal}
                            />
                        </div>
                    </div>
                    <div className="agencies_card__paragraph section_block__text" data-scroll="" data-scroll-fadeup="" dangerouslySetInnerHTML={{__html: data.description}}></div>
                    <div className="agencies_card__bottom" data-scroll="" data-scroll-fadeup="">
                        <div className="agencies_card__bottom_right">
                            <div className="agencies_card__like">
                                <div className="agencies_card__like_icon"> 
                                    <svg className="icon_up">
                                        <use xlinkHref="#thumbs-up"></use>
                                    </svg>
                                </div>
                                <span className="section_block__text">{data.reviewsTotal.plus}</span>
                            </div>
                            <div className="agencies_card__like">
                                <div className="agencies_card__like_icon"> 
                                    <svg className="icon_down">
                                        <use xlinkHref="#thumbs-down"></use>
                                    </svg>
                                </div>
                                <span className="section_block__text">{data.reviewsTotal.minus}</span>
                            </div>
                        </div>
                    </div>
                </Link>
                <Link className="agencies_card__bottom_link" to={`/reviews/${data.id}`} data-scroll="" data-scroll-fadeup="">
                    <span className="text">{getReviewsTotal(data.reviewsTotal.total)}</span>
                    <svg className="icon">
                        <use xlinkHref="#arrow_short"></use>
                    </svg>
                </Link>
            </div>
        </>
    )
}

export default AgencyCard;