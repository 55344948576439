import { createStore, combineReducers, applyMiddleware } from "redux";
import {formUpdate} from "./reducers/formReducer";
import {agenciesUpdate} from "./reducers/agenciesReducer";
import { modalReducer } from "./reducers/modalsReducer";


const rootReducer = combineReducers({
    form: formUpdate,
    agencies: agenciesUpdate,
    modals: modalReducer
});

export const store = createStore(rootReducer, applyMiddleware());