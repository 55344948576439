import React, { useState } from "react";
import SimpleBar from "simplebar-react";
import 'simplebar/dist/simplebar.min.css';

function SortingFilters(props) {
    const {options, isActiveId, onClickFunction} = props;

    const [activeId, setActiveId] = useState(isActiveId ? isActiveId : 1);

    const onClick = (item) => {
        setActiveId(item.id)
        
        if (onClickFunction) {
            onClickFunction(item);
        }
    }

    return (
        <>
            <SimpleBar className="page_top__bottom" data-scroll="" data-scroll-fadeup="">
                {options.map( ( item, index ) => {
                    return (
                        <button 
                            className={`page_top__btn ${activeId == item.id ? "is-active" : ""}`} 
                            key={item.id}
                            onClick={_ => {onClick(item)}}
                            >
                            <span className="page_top__btn_text">{item.title}</span>
                        </button>
                    )
                })}
            </SimpleBar>
        </>
    )
}

export default SortingFilters = React.memo(SortingFilters);