import React from "react";

function Rating (props) {
    const { rating, onClick } = props;

    return (
        <>
            <div className="rating rating--right" onClick={onClick}>
                <svg className="icon">
                    <use xlinkHref="#rating"></use>
                </svg>
                <span className="rating__text">{rating}/10</span>
            </div>
        </>
    )
}

export default Rating;